.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.metadata_container {
  width: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 1.6rem;
}

.audio {
  width: 100%;
  margin-top: 2rem;
}
