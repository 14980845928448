/* @import "~antd/dist/antd.css"; */
@import "react-perfect-scrollbar/dist/css/styles.css";

.ant-menu-vertical .ant-menu-item {
  margin-top: 0;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 8px !important;
}

.ant-table-thead > tr > th {
  padding: 12px 12px !important;
}

.ant-table-content > table > thead > tr {
  font-size: 1.3rem !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

/*Tab*/
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  padding: 0 1.6rem;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0;
}

/*Transfer*/
.ant-transfer-list {
  width: 100% !important;
}

.text_title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 500;
}

.label {
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.text {
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0.65);
}

.text_medium {
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.65);
}

.text-small-11 {
  font-size: 1.1rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.45);
}

.icon_size {
  font-size: 1.2rem;
}

.text_error {
  font-size: 1.3rem;
  line-height: 2.1rem;
  font-weight: 600;
  color: #e74c3c;
}

.text_success {
  font-size: 1.3rem;
  line-height: 2.1rem;
  font-weight: 600;
  color: #2ecc71;
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ant-btn-primary {
  background-color: #feb813;
}
