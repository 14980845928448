.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  gap: 1.6rem;
  background-color: #f4f4f4;
}

.row_container {
  width: 100%;
  height: 15rem;
  background-color: #fff;
  border-radius: 0.6rem;
  display: flex;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
