.header_container {
  width: 100%;
  display: flex;
  align-items: center;
}

.action_container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.table_action_container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
