.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #f0f0f0;
  padding: 1.6rem;
  border-radius: 0.6rem;
  background-color: #fff;
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  /* align-items: center; */
  gap: 1.2rem 1rem;
}

.form_control {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.loading_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
}
