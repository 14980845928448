.main {
  width: 100%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  padding-right: 2rem;
  gap: 1.6rem 1.6rem;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.form_control {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.action_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 1rem;
  gap: 1rem;
}
