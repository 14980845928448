.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.iframe_container {
  width: 100%;
  height: 100%;
  /* border: 1px solid #ddd; */
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;

  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
  background-color: #fff;
}

.status_bar {
  height: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1.6rem;
  gap: 1.6rem;
}

.bar_item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.loading_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
