.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0.5rem;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selection_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.username_container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.text {
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 1.2rem;
}

.pagination_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
