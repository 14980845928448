.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 8rem;
  background-color: #f8f8f8;
  margin-bottom: 1rem;
}

.loading_container {
  width: 3.6rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
