.row {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.icon_container {
  width: 5rem;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}
