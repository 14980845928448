.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  gap: 1.6rem;
  background-color: #f4f4f4;
}

.verify_count_container {
  width: 100%;
  height: 40rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  gap: 1rem;
}

.filter_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.time_filter {
  width: 50rem;
}
