.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 0 1.6rem;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}

.main {
  width: 100%;
  height: calc(100% - 5rem);
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* background-color: #f8f8f8; */
}

.item_container {
  padding: 0.8rem;
}

.statistic_title {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.date_container {
  width: 10rem;
  height: 3.2rem;
}
