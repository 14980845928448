.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  padding-left: 6rem;
}

.message_container {
  width: 100%;
  /* height: calc(100% - 13rem); */
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.text_title {
  font-weight: 600;
  font-size: 1.5rem;
}

.icon {
  font-size: 1.3rem;
}

.action_container {
  width: 100%;
  /* height: 8rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0.8rem 0;
}

.text {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0.65);
}

.text_score {
  margin-left: 6rem;
}

.empty_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
