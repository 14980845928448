.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.message_container {
  width: 100%;
  height: calc(100% - 5rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.message {
  font-weight: 500;
  font-size: 1.4rem;
}

.action_container {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
