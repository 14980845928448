.container {
  width: 100%;
  height: 100%;
  padding-top: 0.5rem;
  /* background-color: #f8f8f8; */
}

.main {
  width: 100%;
  height: calc(100vh - 14.25rem);
  padding: 1.6rem;
  background-color: #f4f4f4;
}
