.container {
  width: 100%;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #f0f0f0;
  border-radius: 0.6rem;
  background-color: #ffff;
}
