.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.permissions_container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem 0;
}

.text {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0.65);
}

.icon {
  font-size: 1.2rem;
}
