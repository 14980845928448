.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.json_container {
  width: 100%;
  height: calc(100% - 5rem);
  /* background-color: rebeccapurple; */
}
