.container {
  width: 32rem;
  height: calc(100vh - 4.8rem);
  background-color: #fff;

  position: absolute;
  left: 0rem;
  top: 4.8rem;
  z-index: 11;
  box-shadow: 0 2.7px 9px rgb(0 0 0 / 13%), 0 9.4px 24px rgb(0 0 0 / 9%),
    0 21.8px 43px rgb(0 0 0 / 8%);

  display: flex;
  flex-direction: column;
}

.close_btn {
  width: 2.4rem;
  height: 2.4rem;
  background-color: #69707d;
  color: #fff;

  position: absolute;
  top: 1rem;
  left: 33rem;

  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 5; */
}

.close_btn:hover {
  cursor: pointer;
}

.menu_item_container {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 1.3rem;
  border-radius: 0.6rem;
}

.menu_item_container:hover {
  cursor: pointer;
}

.main {
  width: 100%;
  height: calc(100% - 6rem);
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.user_info {
  width: 100%;
  height: 6rem;
  border-top: 1px solid #f0f0f0;
}

.user_info_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.user_avatar {
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  background: #e67e22;
  font-size: 1.4rem !important;
}

.user_main {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
}

.user_action {
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_username {
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_role {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.45);
}
