.form_control {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.btn_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 1rem;
}
