.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 0.6rem;
}

.opt_container {
  height: 100%;
  min-height: 3.2rem;
  width: 4rem;
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #f0f0f0;
  background-color: #f4f4f4;
  /* border-radius: 0.4rem 0 0 0.4rem; */
}

.opt_container:hover {
  cursor: pointer;
}

.range_container {
  height: 100%;
  width: calc(100% - 4rem);
}

.full {
  width: 100%;
  height: 100%;
  font-size: 1.3rem;
  line-height: 2.1rem;
}
