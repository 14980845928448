.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}

.text_404 {
  font-weight: 600;
  font-size: 8rem;
}
