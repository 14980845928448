.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 0 1.6rem;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}

.date_container {
  width: 10rem;
  height: 3.2rem;
}

.main {
  width: 100%;
  height: calc(100% - 5rem);
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.statistic_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.statistic_main {
  width: 100%;
  height: 35rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
