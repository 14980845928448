.footer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.label {
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.required_label {
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.required_label::after {
  content: "*";
  color: #d63031;
  margin-left: 3px;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
}

.form_control {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.text {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0.65);
}

.user_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.tag {
  margin-top: 0.5rem;
}
