.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  padding-left: 6rem;
}

.message_container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.text_title {
  font-weight: 600;
  font-size: 1.5rem;
}

.action_container {
  width: 100%;
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  padding-bottom: 1rem;
}

.text {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0.65);
}

.empty_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
