.container {
  width: 100%;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.6rem;
  background-color: #2d3436;
}

.logo_container {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logo {
  width: 2.5rem;
  object-fit: contain;
}

.text_logo {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
}

.avatar {
  background: #e67e22;
  font-size: 1.4rem !important;
}

.avatar:hover {
  cursor: pointer;
}
