.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.top_container {
  width: 100%;
  height: 9.6rem;
  display: flex;
  flex-direction: column;
}

.main {
  width: 100%;
  height: calc(100% - 9.6rem);
  background-color: #fff;
}
