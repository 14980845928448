.main {
  width: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 2.5rem;
}

.info_filter {
  width: 100%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 1rem;
}

.status_filter {
  width: 100%;
}

.form_control {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.action_container {
  width: 100%;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
