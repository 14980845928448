.container {
  position: absolute;
  top: 4.5rem;
  left: 0;
  /* width: 100%; */
  padding: 1rem 1.6rem;
  background-color: #fff;
  z-index: 12;

  border-radius: 0.6rem;
  box-shadow: rgb(0 0 0 / 8%) 0px 0.9px 4px -1px,
    rgb(0 0 0 / 6%) 0px 2.6px 8px -1px, rgb(0 0 0 / 5%) 0px 5.7px 12px -1px,
    rgb(0 0 0 / 4%) 0px 15px 15px -1px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.text_title {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
  font-size: 1.3rem;
}

.quick_select_container {
  width: 100%;
  height: 3.3rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.text {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0, 65);
}

.input_number {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0, 65);
  min-width: 6rem;
  width: 100%;
  height: 100%;
}

.common_used_container {
  width: 100%;
  height: 15rem;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto auto auto;
  /* background-color: rebeccapurple; */
}

.text_common {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: #3498db;
}

.text_common:hover {
  cursor: pointer;
  text-decoration: underline;
}

.footer {
  height: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
