.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
}

.left_container {
  height: 100%;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 1.6rem;
  background-color: #f8f8f8;
  border-right: 1px solid #f0f0f0;
}

.login_img {
  width: 100%;
  object-fit: contain;
}

.right_container {
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.6rem;
}

.main {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.icon {
  color: rgba(0, 0, 0, 0.25);
}

.text_center {
  text-align: center;
}
