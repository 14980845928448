.container {
  width: 30rem;
  height: 16rem;
  display: flex;
  flex-direction: column;
}

.language_container {
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  flex-direction: column;
}

.language {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.text {
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.65);
}

.menu_container {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
}

.menu_item {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 0.6rem;
}

.menu_item:hover {
  cursor: pointer;
  background-color: #f8f8f8;
}
