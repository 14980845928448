.container {
  width: 100%;
  height: 100%;
  padding: 1.6rem;

  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  background-color: #f4f4f4;
}
